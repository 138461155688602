import moment from "moment";

const preDefinedDates = {
   currentMonth: currentMonth(),
   currentYear: currentYear(),
   lastMonth: lastMonth(),
   lastThirty: lastThirty(),
   monthYear: getMonth,
   validDate: isValidDate
};
const months = [
   "Janeiro",
   "Fevereiro",
   "Março",
   "Abril",
   "Maio",
   "Junho",
   "Julho",
   "Agosto",
   "Setembro",
   "Outubro",
   "Novembro",
   "Dezembro"
];

function compareDates(arrayOfDates) {
   if (!arrayOfDates || !arrayOfDates.length) return new Date().toISOString().slice(0, 10);

   let dates = arrayOfDates.map(dateItem => new Date(dateItem.date).getTime());
   return new Date(dates.sort()[dates.length - 1]).toISOString().slice(0, 10);
}

export function toTimeZone(incomingDate, asDate) {
   const dateObject = new Date(incomingDate);

   if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid date input");
   }

   const adjustedDate = new Date(
      Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 12, 0, 0)
   );

   if (asDate) {
      return adjustedDate;
   } else {
      return adjustedDate.toISOString().substring(0, 10);
   }
}

function lastThirty() {
   const date = new Date(new Date().setDate(new Date().getDate() - 30));

   return {
      start: new Date(date).toISOString().slice(0, 10),
      end: new Date().toISOString().slice(0, 10)
   };
}

function currentMonth() {
   let thisMonth, lastDay;

   thisMonth = new Date();
   thisMonth.setDate(1);
   thisMonth = toTimeZone(thisMonth);

   lastDay = new Date();
   lastDay = new Date(lastDay.getFullYear(), lastDay.getMonth() + 1, 0);
   lastDay = lastDay.toISOString().slice(0, 10);

   return {
      start: thisMonth,
      end: lastDay
   };
}

function currentYear() {
   let date = new Date(new Date(new Date().setMonth(0)).setDate(1));
   date = toTimeZone(date);

   const endDate = toTimeZone(new Date());
   return {
      start: date,
      end: endDate
   };
}

function lastMonth() {
   let lastMonthDate, endOfMonth;

   lastMonthDate = new Date();
   lastMonthDate.setDate(1);
   lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
   lastMonthDate = new Date(lastMonthDate);
   lastMonthDate = toTimeZone(lastMonthDate);

   endOfMonth = new Date();
   let lastMonthNumber = endOfMonth.getMonth() + 1;
   if (lastMonthNumber > 0) lastMonthNumber = lastMonthNumber - 1;

   endOfMonth = new Date(endOfMonth.getFullYear(), lastMonthNumber, 0);
   endOfMonth = toTimeZone(endOfMonth);

   return {
      start: lastMonthDate,
      end: endOfMonth
   };
}

function getMonth(date) {
   date = date || new Date();
   return `${months[new Date(date).getMonth()]} ${new Date(date).getFullYear()}`;
}

export function todayIsAfter(date) {
   const today = moment();
   date = moment(date);
   return today.isAfter(date, "months");
}

class Moment {
   static format(date) {
      moment.locale("pt-Br");
      return moment(date).format("LLLL");
   }

   static getPrevMonth(currentMonth) {
      return moment(currentMonth)
         .subtract(1, "month")
         .format("MM/YYYY");
   }

   static getNextMonth(currentMonth) {
      return moment(currentMonth)
         .add(1, "month")
         .format("MM/YYYY");
   }

   static add(date, number, unit, format = "DD/MM/YYYY") {
      return moment(date)
         .add(number, unit)
         .format(format);
   }
}

export function getMonthFormated(element) {
   return `${months[parseInt(element - 1)].substring(0, 3).toUpperCase()}.`;
}

function isValidDate(date) {
   date = new Date(date);
   return date instanceof Date && !isNaN(date);
}

export default class Transform {
   static toLocale(date) {
      return new Date(date).toLocaleDateString("pt-br", { timeZone: "UTC" });
   }
   static format(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
   }
   static parse(date) {
      if (!date) return "";

      const [day, month, year] = date.split("/");

      let parsedDate = `${year}-`;
      parsedDate += month ? `${month.padStart(2, "0")}-` : `01-`;
      parsedDate += day ? `${day.padStart(2, "0")}` : `01`;

      return parsedDate;
   }
   static roundDate(date) {
      const dateValue = date ? new Date(date) : new Date();

      const dateInMilliseconds = dateValue.setDate(2);
      return new Date(dateInMilliseconds);
   }
   static manageDays(month, date) {
      let start, end;
      date = new Date(this.parse(date));

      start = new Date(date.getFullYear(), date.getMonth() + parseInt(month) + 1, 1)
         .toISOString()
         .slice(0, 10);
      end = new Date(date.getFullYear(), date.getMonth() + parseInt(month) + 2, 0)
         .toISOString()
         .slice(0, 10);

      return {
         start: this.format(start),
         end: this.format(end)
      };
   }
}

const error = {
   UNEXPECTED_TYPE: "Received unexpected type, got: "
};
class DateManager {
   /**
    * Adds or removes amount of months of an arbitrary Date.
    *
    * @param {String} type Sum or Subtract
    * @param {Date} date The date will be based
    * @param {Number} amount The amount of months that will be deduced
    * @param {Boolean} formatted Optional formatter
    * @returns {Object} Returns { start, end } dates, formatted or not
    * @throws {TypeError} When type is unexpected
    */
   static getMonthSpec({ type = "sum", date = new Date(), amount = 1, formatted = false } = {}) {
      const ADD_MONTHS = "sum",
         REMOVE_MONTHS = "subt";

      let start, end;

      switch (type) {
         case ADD_MONTHS:
            date.setMonth(date.getMonth() + amount);
            break;
         case REMOVE_MONTHS:
            date.setMonth(date.getMonth() - amount);
            break;
         default:
            throw TypeError(error.UNEXPECTED_TYPE + String(type));
      }

      start = new Date(new Date(date).setDate(1));
      end = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      start = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 3, 0, 0));
      end = new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), 3, 0, 0));

      if (formatted) {
         return {
            start: toTimeZone(start),
            end: toTimeZone(end)
         };
      }

      return { start, end };
   }

   static createRange(date) {
      let [month, year] = date.split("/").map(Number); // Converte diretamente para números
      let amount = 0;

      if (month === 13) {
         month = 1;
         year += 1;
         amount = 1;
      }

      let dateReturn = new Date(year, month - 1, 1);
      let formatted = true;

      // Verifica se a data é válida
      if (isNaN(dateReturn.getTime())) {
         formatted = false;
      }

      // Assume que a data é válida após a criação correta
      return this.getMonthSpec({
         date: dateReturn,
         amount,
         formatted
      });
   }
}
export { preDefinedDates, compareDates, Transform, DateManager, Moment, error };
